<template>
	<v-card color="lightest" flat class="rounded-lg">
		<v-card-text>
			<h4>{{title}}:</h4>
			<v-item-group class="mt-2" :value="value" @change="v => $emit('input', v)" multiple>
				<v-item
					v-for="option in options"
					:key="option"
					v-slot="{ active, toggle }"
					:value="option"
				>
					<v-chip
						class="mr-2"
						:color="active ? 'success' : 'secondary'"
						@click="toggle"
						>{{ option }}</v-chip
					>
				</v-item>
			</v-item-group>
		</v-card-text>
	</v-card>
</template>
<script>
export default {
    name: "ListFilters", 
    props: {
        value: {type: Array}, 
        options: {type: Array},
        title: {type: String}
    }
}
</script>