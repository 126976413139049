<template>
	<v-sheet>
		<list-filter
			v-model="filterContent"
			:options="filterOptions"
			title="Filter By Live Content"
		></list-filter>
		<mw-list storeModule="content" :columns="headers" :filtered="includeIds">
			<template v-slot:item.html="slotProps">
				<span
					v-if="slotProps.html"
					v-html="`${slotProps.html.substr(0, 100)}...`"
				></span>
			</template>
			<template v-slot:item.updated_at="slotProps">
				<span v-if="slotProps.updated_at">
					{{ slotProps.updated_at.toDate().toLocaleDateString() }}
				</span>
			</template>
			<template v-slot:item.promote_content="slotProps">
				<span v-if="slotProps.promote_content">
					{{ slotProps.promote_content.toDate().toLocaleDateString() }}
				</span>
			</template>
		</mw-list>
		<import storeModule="content" defaultFields="name,title,html"></import>
	</v-sheet>
</template>

<script type="text/javascript">
import Import from "@c/buttons/Import";
import MwList from "@c/ui/MwList";
import ListFilter from "@c/lists/ListFilter";
export default {
	name: "Content",
	data: () => {
		return {
			filtered: false,
			filterContent: [],
			filterOptions: [
				"client",
				"participant",
				"contract_manager",
				"promoted",
			],
		};
	},
	computed: {
		content() {
			return this.$store.state.content.data;
		},
		headers() {
			return [
				{ value: "id", text: "ID", default: true },
				{ value: "title", text: "Title", default: true },
				{ value: "html", text: "Content", default: true },
				{ value: "live_content", text: "Live Content", default: true },
				{
					value: "promote_content",
					text: "Promote Content",
					default: true,
				},
				{ value: "updated_at", text: "Last Updated", default: true },
			];
		},
		includeIds() {
			const self = this;
			let ids = Object.keys(self.content);
			let filters = [...self.filterContent];
			if (filters.length) {
				if (filters.includes("promoted")) {
					ids = ids.filter((id) => self.content[id].promote_content);
				}
				let roles = filters.filter(a => a !== "promoted");

				ids = ids.filter((id) =>
					self.includesRole(self.content[id].live_content, roles)
				);
			}
			return ids;
		},
	},
	methods: {
		includesRole(arr = [], roles = []) {
			var match = false;
			if (roles.length == 0) {
				match = true;
			} else {
				roles.forEach((r) => {
					if (arr.includes(r)) {
						match = true;
					}
				});
			}
			return match;
		},
	},
	components: {
		MwList,
		Import,
		ListFilter,
	},
};
//
</script>
"
